<template>
  <div class="app-Manufacturer">
    <div class="Manufacturer-wrapper">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark" style="text-align: left">
            <h1 style="font-size: 30px">{{ this.$t('订阅新闻') }}</h1>
            <p>{{this.$t('hint.6')}}</p>
          </div>
          <el-col :span="24" style="text-align: right;">
            <el-button>{{this.$t('btn.continue')}}</el-button>
          </el-col>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Manufacturer',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}
</style>
